/*===
  NAV
  ===*/

  /* Nav-specific settings */

  $burger: 24px;
  $burgerBar: $burger / 8;

  $mobileLogo: 38px;
  $desktopLogo: 38px;

  /* Helper mixins */

  @mixin navLogo($size){
    height: $size;
  }

  @mixin navAnchor {
    &:after {
      content: '+';
      display: inline-block;
      position: absolute;
      right: 10px;
      top: 10%;
      float: right;
      padding: 3px;
      color: $white;
      font-family: $aleck;
      font-size: 25px;
      font-weight: 300;
      cursor: pointer;
    }
  }

  @mixin navAnchor-expanded {
    &:after {
      content: '';
      background-color: $white;
      padding: 0;
      height: 2px;
      width: 12px;
      top: 19px;
      left: 14px;
    }
  }

  @mixin activeItem {
    & > .nav__menu-item {
      color: $color--cta;

      @media (min-width: $breakpoint--desktopNav) {
        &:before {
          opacity: 1;
        }
      }
    }
  }

  @mixin activeItem-darkened {
    & > .nav__menu-item {
      text-shadow: 0px 0px 30px rgba(0, 0, 0, .5);
    }
  }

/*=====================*/

  #att-about-header .nav {
    @include clearfix;
    max-width: $maxContentWidth;
    margin: 0 auto;
    height: 100%;
    -webkit-font-smoothing: subpixel-antialiased;
  }
  #att-about-header .plusMicrosite .nav__utils {
    height: $micrositeCollapsedGlobalNav;
  }

  #att-about-header .nav__utils {
    @include clearfix;
    position: relative;
    box-sizing: border-box;
    padding: 0 $air--xSmall;
    height: $mobileNavHgt;

    @media (min-width: $breakpoint--desktopNav) {
      padding: 0 $gutter--small;
      float: left;
      width: 15%;
    }

    @media (min-width: $breakpoint--xLarge) {
      padding: 0;
    }
  }

  /* Nav Logo */

  .nav__logo {
    @include centerVertically;
    display: block;
    float: left;

    .logo__icon {
      @include transitionAll(300ms);
      @include navLogo($mobileLogo);
      min-width: 95px;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100%;
      display: block;

      /* NOTE: Logo color can vary based on the theme. Default treatment is silver. */

      background-image: url('../img/logo_att-white-text.png');

      // @each $theme, $color in $themeColors {
      //   $colorName: nth($color, 1);
      //
      //   .theme--#{$theme} & {
      //     background-image: url('../img/logo_att-#{$colorName}.png');
      //   }
      // }

      @media (min-width: $breakpoint--large) {
        @include navLogo($desktopLogo);
        min-width: 95px;

        .header--fixed & {
          @include navLogo($mobileLogo);
          min-width: 95px;
        }
      }
    }
  }

  /* Hamburger Toggle */

  #att-about-header .nav__toggle,
  .microsite-nav--plusGlobal .nav__toggle {
    height: $burger;
    width: $burger;
    background-color: transparent;
    padding: 0;
    border: 0;

    &:focus {
      outline: 0;
    }

    @media (min-width: $breakpoint--desktopNav) {
      display: none;
    }
  }

  #att-about-header .nav__toggle .bar,
  .microsite-nav--plusGlobal .nav__toggle .bar {
    @include transitionAll(300ms);
    display: block;
    background: $white;
    width: 100%;
    height: $burgerBar;
    margin-bottom: $burgerBar + 1;
  }

  .nav__toggle--open {
    @include centerVertically;
    float: right;

    .nav--open & {
      display: none;
    }
  }

  .nav__toggle--close {
    display: none;

    .nav--open & {
      display: block;
      position: absolute;
      top: 22px;
      // left: -40px;
      right: 20px;

      @media (min-width: $breakpoint--desktopNav) {
        display: none;
      }
    }
    .nav--open & .bar {
      position: absolute;
      background: none;
      top: 8px;

      &:first-child,
      &:last-child {
        background: $white;
      }

      &:first-child {
        transform: rotate(-45deg);
      }

      &:last-child {
        transform: rotate(45deg);
      }
    }
  }

  #att-about-header .nav__utils,
  #att-about-header .nav__menu--primary {
    @media (min-width: $breakpoint--desktopNav) {
      height: 100%;
    }
  }

  /* Nav Menus */

  .nav__menu {
    position: absolute;
    box-sizing: border-box;
    background-color: $black;

    @media (min-width: $breakpoint--desktopNav) {
      padding-right: $gutter--small;
    }

    @media (min-width: $breakpoint--large) {
      padding-right: $gutter--small;
    }

    li {
      position: relative;
      list-style-type: none;
      margin: 0;
      z-index: 10;
      text-align: left;

      &.expanded > .nav__menu-parent {
        @include navAnchor-expanded;
      }
    }

    .nav__menu-item {
      display: inline-block;
      box-sizing: border-box;
      position: relative;
      height: 100%;
      width: 100%;
      font-family: $aleck;
      font-weight: 300;
      font-size: 16px; // don't use rems here
      line-height: 1.25;
      text-decoration: none;
      color: $white;

      @media (min-width: $breakpoint--desktopNav) {
        font-size: 14px; // don't use rems here
      }

      span {
        display: inline-block;
        position: relative;

        @media (max-width: 360px) {
          font-size: 14px; // don't use rems here
        }
      }
    }

    .nav__menu-parent {
      @include navAnchor;
      position: absolute;
      display: block;
      height: 40px;
      width: 40px;
      padding: 10px 0;
      right: 0;
      top: 0;
      line-height: 1;
      text-decoration: none;

      @media (min-width: $breakpoint--desktopNav) {
        height: 37px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  /* Primary Menus */

  .nav__menu--primary {
    width: $navWidthMobile;
    padding-left: 0;
    margin: 0;

    @media (min-width: $breakpoint--small) {
      width: $navWidthTablet;
      &:focus {
        outline: none;
      }
    }

    @media (max-width: $breakpoint--desktopNav - 1) {
      transition: width 300ms ease;
      max-width: 80%;
      left: 100%;
      top: 0;
      height: 100%;
      padding-top: $mobileNavHgt;
      border-left: 1px solid $drkGray;

      @media (min-width: 360px) {
        max-width: none;
      }
    }

    @media (min-width: $breakpoint--desktopNav) {
      position: relative;
      float: right;
      width: 85%;
      background-color: transparent;
      text-align: right;
    }

    @media (min-width: $breakpoint--xLarge) {
      padding-right: 0;
    }

    & > li {

      /* NOTE: Apply highlighting to nav items based on the body class name. */

      @each $bodyClass, $navItemClass in $navHighlights {
        .#{$bodyClass}:not([class*="nav-"]) &.#{$navItemClass},
        .#{$navItemClass} &.#{$navItemClass} {
          @include activeItem;
        }

        .#{$bodyClass}:not([class*="nav-"]) .header--transparent &.#{$navItemClass},
        .#{$navItemClass} .header--transparent &.#{$navItemClass} {
          @include activeItem-darkened;
        }
      }

      &.active {
        @include activeItem;
      }

      .header--transparent &.active {
        @include activeItem-darkened;
      }

      @media (max-width: $breakpoint--desktopNav - 1) {
        border-bottom: 1px solid $drkGray;

        &.expanded > .nav__menu-item {

          &:after {
            transform: translate(1px, -2px) rotate(45deg);
          }

            span {
              border-bottom: 2px solid $color--cta;
              padding-bottom: 9px;
          }
        }
      }

      @media (min-width: $breakpoint--desktopNav) {
        display: inline-block;
        height: 100%;
        margin: 0 0 0 20px;

        &:first-child {
          margin-left: 0;
        }

        &:nth-last-child(-n+2) > .nav__menu--nested {
          right: 0;
        }

        &:hover > .nav__menu-item,
        &.expanded > .nav__menu-item {
          &:before {
            opacity: 1;
          }

          &:after {
            transform: translate(1px, -2px) rotate(45deg);

          }
        }
      }

      @media (min-width: 850px) {
        margin-left: 35px;
      }

      @media (min-width: $breakpoint--medium) {
        margin-left: 65px;
      }

      @media (min-width: $breakpoint--large) {
        margin-left: 80px;
      }

      @media (min-width: $breakpoint--xLarge) {
        &.last-item {
          padding-right: 0;
          margin-right: 0;
        }
      }

      @media (min-width: $breakpoint--xxLarge) {
        &:nth-last-child(-n+2) > .nav__menu--nested {
          right: auto;
        }

        &:last-child > .nav__menu--nested {
          right: 0;
        }
      }

      & > .nav__menu-item {
        @media (max-width: $breakpoint--desktopNav - 1) {
          width: calc(100% - 100px);
          padding: 10px 0 10px 20px;
        }

        @media (min-width: $breakpoint--desktopNav) {
          @include highlight-bar($highlightBar);
          text-transform: uppercase;
          font-weight: 400;
          &:focus:before {
            opacity: 1;
          }
        }

        span {
          @media (min-width: $breakpoint--desktopNav) {
            display: block;
            top: 50%;
            transform: translate(0, -50%);
          }
        }
      }

      & > .nav__menu-parent {
        @media (min-width: $breakpoint--desktopNav) {
          height: 0;
          width: 0;
          padding: 0;

          &:after {
            content: none;
          }
        }
      }

      &.expanded > .nav__menu-parent:after {
        @media (min-width: $breakpoint--desktopNav) {
          content: none;
        }
      }
    }
  }

//Expanded global navigation when microsite navigation is present
.microsite-nav--plusGlobal{
  .nav__menu--primary{
    @media (min-width: $breakpoint--desktopNav) {
      position: relative;
      float: right;
      width: 85%;
      background-color: transparent;
      text-align: right;
      top:45%;
    }  
  }
  .nav__logo {
    position: relative;
    -webkit-transform: translateY(20%);
    -ms-transform: translateY(20%);
    transform: translateY(20%);
    top: 50%;
    display: block;
    float: left;
}
}

  /* Subnavs */

  .nav__menu--secondary,
  .nav__menu--tertiary {
    display: none;
    position: relative;

    @media (min-width: $breakpoint--desktopNav) {
      position: absolute;
      width: 260px;
      margin-top: 1px;
      padding: 10px 0;

      .nav__menu--nested {
        position: relative;
        background-color: $black;
        margin-top: 0;
        padding: 5px 10px;
      }
    }

    @media (min-width: $breakpoint--large) {
      width: 300px;
    }

    @media (min-width: $breakpoint--xLarge) {
      width: 340px;
    }

    li {
      @media (max-width: $breakpoint--desktopNav - 1) {
        border-bottom: 1px solid $drkGray;
      }

      &:hover {
        @media (min-width: $breakpoint--desktopNav) {
          background-color: $drkGray;
        }
      }

      &:hover .nav__menu-parent {
        @media (min-width: $breakpoint--desktopNav) {
          background-color: lighten($drkGray, 5%);
        }
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    .nav__menu-item {
      width: calc(100% - 100px);
      padding: 10px 0 10px 0;

      @media (min-width: $breakpoint--desktopNav) {
        padding-left: 20px;
        width: calc(100% - 40px);
      }

      &:focus {
        @media (min-width: $breakpoint--desktopNav) {
          background-color: $drkGray;
        }
      }

      &:last-child {
        width: 100%;
        padding-right: 20px;
      }
    }
  }

  /* Secondary Subnavs */

  .nav__menu--secondary {

    @media (max-width: $breakpoint--desktopNav - 1) {
      padding-left: 40px;

      /* When in mobile, rely on the .expanded class of the parent element to show secondary navs */
      .expanded > .nav__menu-parent + & {
        display: block;
      }
    }

    /* When in desktop, rely on the hover or focused state of the parent element to show secondary navs */
    @media (min-width: $breakpoint--desktopNav) {
      .nav__menu--primary > li:hover > .nav__menu-parent + &,
      .nav__menu--primary > li.expanded > .nav__menu-parent + & {
        display: block;
      }
    }
  }

  /* Tertiary Subnavs */

  .nav__menu--tertiary {

    @media (max-width: $breakpoint--desktopNav - 1) {
      padding-left: 25px;
    }

    @media (min-width: $breakpoint--desktopNav) {
      position: relative;
      background-color: $black;
      margin-top: 0;
      padding: 5px 10px;
    }

    li .nav__menu-item {
      @media (min-width: $breakpoint--desktopNav) {
        padding-left: 30px;
      }
    }

    /* Always rely on the .expanded class of the parent element to show tertiary navs */
    .expanded > .nav__menu-parent + & {
      display: block;
    }
  }
