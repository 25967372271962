/*======
  MIXINS
  ======*/

/*=======
  Accents
  =======*/

@mixin accent-arrow($direction) {
  position: relative;

  &:after {
    @if $direction=="up" {
      content: " \2191";
    }

    @else if $direction=="down" {
      content: " \2193";
    }

    @else if $direction=="left" {
      content: " \2190";
    }

    @else if $direction=="right" {
      content: " \2192";
    }

    color: inherit;
    font-weight: 400;
  }
}

@mixin special-bold($color) {
  @include font-size(24);
  font-family: $aleck;
  font-weight: 300;

  b,
  strong {
    font-weight: 700;
    color: $color;

    .region--blue & {
      color: $black;
    }

    .region--dark-blue &,
    .region[class*="-black"] & {
      color: $white;
    }
  }
}

@mixin gradient-overlay($startPosition, $opacity) {
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) $startPosition, rgba(0, 0, 0, $opacity) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) $startPosition, rgba(0, 0, 0, $opacity) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) $startPosition, rgba(0, 0, 0, $opacity) 100%);

    .no-cssgradients & {
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#330000', endColorstr='#000000', GradientType=1);
      -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#330000', endColorstr='#000000', GradientType=1);
    }
  }
}

@mixin solid-overlay($opacity) {
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, $opacity);
  }
}

@mixin highlight-bar($height) {
  &:before {
    @include transitionAll(300ms);
    content: "";
    display: block;
    position: absolute;
    bottom: -1px;
    height: $height;
    width: 100%;
    background-color: $color--cta;
    opacity: 0;
  }
}

@mixin icon-overlay($icon) {
  position: relative;

  &:after {
    content: map-get($attIcons, $icon);
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: $att-icons;
    font-size: 10rem;
    text-align: center;
    color: $white;
    opacity: 0.85;

    @media (max-width: $breakpoint--xxSmall) {
      font-size: 8rem;
    }

    .col-2 & {
      @media (min-width: $breakpoint--small) {
        font-size: 8rem;
      }
    }

    .col-3 & {
      @media (min-width: $breakpoint--medium) {
        font-size: 8rem;
      }
    }

    .col-4 & {
      @media (min-width: $breakpoint--small) and (max-width: $breakpoint--medium - 1) {
        font-size: 8rem;
      }
    }
  }
}

/*==========
  Animations
  ==========*/

@mixin transitionAll($speed) {
  transition: all $speed ease;
}

/*=================
  Container Queries
  =================*/

@mixin respond-to-context($sizes...) {
  /*
     * Available component formats are xsmall, small, medium or large.
     * This mixin loops over the contexts provided (column sizes) and determines when to apply the contained styles for those contexts,
     * and assigns each of those potential column sizes to a corresponding component format (xsmall, small, medium or large).
     * The :not(.col) parent filter is always included to make sure that these styles also get applied for components that are being used outside of columns system
     */

  @each $size in $sizes {
    @if $size=="small" {

      /* At mobile viewports, every component in every column starts out adhering to its small format */
      // .region :not(.col) &,
      .col-2 &,
      .col-3 &,
      .col-4 &,
      .col-6 &,
      .col-8 &,
      .col-9 &,
      .col-12 & {
        @content;
      }

    }

    @elseif $size=="medium" {

      /* Then, at tablet viewports... */
      @media (min-width: $breakpoint--small) {

        /* ...only components inside of the widest columns should adhere to their medium formats */
        // .region :not(.col) &,
        .col-8 &,
        .col-9 &,
        .col-12 & {
          @content;
        }

      }

      /* Then, at smaller desktop viewports... */
      @media (min-width: $breakpoint--medium) {

        /* ...components inside of larger mid-size columns can start to adhere to their medium formats */
        // .region :not(.col) &,
        .col-6 & {
          @content;
        }

      }

      /* Then, at larger desktop viewports... */
      @media (min-width: $breakpoint--large) {

        /* ...components inside of smaller mid-size columns can start to adhere to their medium formats if they are in horizontally flush rows */
        // .region :not(.col) &,
        .row--flush-horizontal .col-4 & {
          @content;
        }

      }

    }

    @elseif $size=="large" {

      /* Then, at tablet viewports... */
      // @media (min-width: $breakpoint--small) {
      //
      //   /* ...only components inside of the widest column should adhere to their large formats */
      //   .col-12 & {
      //     @content;
      //   }
      //
      // }

      /* Then, at smaller desktop viewports... */
      @media (min-width: $breakpoint--medium) {

        /* ...only components inside of the widest columns can start to adhere to their large formats */
        // .region :not(.col) &,
        .col-8 &,
        .col-9 &,
        .col-12 & {
          @content;
        }

      }

      /* Then, at larger desktop viewports ... */
      @media (min-width: $breakpoint--large) {

        /* ...components inside of larger mid-size columns can start to adhere to their large formats */
        // .region :not(.col) &,
        .col-6 & {
          @content;
        }

      }

    }

    @elseif $size=="xsmall" {

      /* Then, at larger desktop viewports... */
      @media (min-width: $breakpoint--large) {

        /* ...only components inside of the tiniest columns should adhere to their xsmall formats */
        // .region :not(.col) &,
        .col-2 & {
          @content;
        }

      }

    }
  }
}

/*===========
  Positioning
  ===========*/

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin centerHorizontally {
  position: relative;
  transform: translateX(-50%);
  left: 50%;
}

@mixin centerVertically {
  position: relative;
  transform: translateY(-50%);
  top: 50%;
}

@mixin row($width: $container) {
  $container: $width;

  max-width: $container;
}

@mixin column($numCols) {
  $gridBase: 12;

  // // Gutter stuff
  // $gutterBase: 20;
  // @if $numCols == 5 {
  //   $gutterBase: 72;
  // }
  // $numGutters: $gridBase / ($numCols - 1);
  // $gutterWidth: ($gutterBase / $gridBase) * 1%;

  $columnWidth: ($numCols / $gridBase) * 100%;

  max-width: $columnWidth;
  flex-basis: $columnWidth;
}

@mixin hidden-spoken {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  position: absolute !important;
  word-wrap: normal !important;

  &:focus {
    clip: auto !important;
    clip-path: none;
    z-index: 100000;
    outline: 0;
  }
}

/*====
  Misc
  ====*/

/* Silver style */

@mixin bkg-silver {
  background: #EDECEC;
  background: -moz-linear-gradient(left, rgba(237, 236, 236, 1) 0%, rgba(249, 249, 249, 1) 100%);
  background: -webkit-linear-gradient(left, rgba(237, 236, 236, 1) 0%, rgba(249, 249, 249, 1) 100%);
  background: linear-gradient(to right, rgba(237, 236, 236, 1) 0%, rgba(249, 249, 249, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#EDECEC', endColorstr='#F9F9F9', GradientType=1);

  .no-cssgradients & {
    filter: none;
  }

  .cta--link {
    color: $attDrkBlue;
  }
}
@mixin gradientBkgd($color, $direction, $textColor: $black) {
  //TODO - confirm that this the correct use of filters
  .cssgradients & {
    filter: none;
  }
  @if $color=="blue" {
    background:$attBlue;
    background:linear-gradient(to $direction, #0079b1 0%, #00C9FF 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0079b1', endColorstr='#00C9FF', GradientType=1);
    @if $textColor=="white" {
      color:$white;
    }
  }

  @elseif $color=="colbalt-purple" {
    background:$colbalt;
    background:linear-gradient(to $direction, $colbalt 0%, $purple 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$colbalt}', endColorstr='#{$purple}', GradientType=1);
    color:$white;
  }

  @elseif $color=="berry-tangerine" {
    background:$berry;
    background:linear-gradient(to $direction, $berry 0%, $tangerine 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$berry}', endColorstr='#{$tangerine}', GradientType=1);
    color:$black;
  }

  @elseif $color=="lime-mint" {
    background:$lime;
    background:linear-gradient(to $direction, $lime 0%, $mint 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$lime}', endColorstr='#{$mint}', GradientType=1);
    color:$black;
  }
}

/* Default button style for white, gray or black backgrounds */

@mixin cta($bkgColor) {
  @if $bkgColor=="blue" {
    border:0;
    background-color: $color--cta;
    color: $white;
    text-decoration: none;

    &:hover {
      background-color: $color--cta-hover;
      color: $white;
    }

  }

  @elseif $bkgColor=="black" {
    border:0;
    background-color: $black;
    color: $white;
    text-decoration: none;

    &:hover {
      background-color: $white;
      color: $black;
    }
  }

  @elseif $bkgColor=="white" {
    border: 2px solid $white;
    background-color: $white;
    color: $black;
    text-decoration: none;

    &:hover {
      background-color: transparent;
      color: $white;
    }
  }
}

@mixin cta-new($color, $hoverTextColor) {
    border: 1px solid $color;
    background-color: transparent;
    color: $color;
    text-decoration: none;

    &:hover {
      background-color: $color;
      color: $hoverTextColor;
    }
}

/* Horizontal/Vertical Rules */

@mixin rule($direction) {
  content: "";
  display: block;
  position: absolute;
  background-color: $attBlue;
  border-radius: 30px;
  z-index: 2;

  @if $direction=="horizontal" {
    max-width: 300px;
    width: 60%;
    height: 4px;
    margin: 0 auto;
    bottom: -2px;
    right: 0;
    left: 0;
  }

  @else {
    max-height: 300px;
    height: 60%;
    width: 4px;
    margin: auto 0;
    left: -2px;
    bottom: 0;
    top: 0;
  }

  .region--blue & {
    background-color: $black;
  }

  .region--dark-blue & {
    background-color: $white;
  }
}

/*==========
  Typography
  ==========*/

@mixin font-size($pixels: 1.4) {
  font-size: $pixels + px;
  font-size: ($pixels / 10) + rem;
}

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin no-font-smoothing {
  -webkit-font-smoothing: auto;
}