/**
 * AT&T About Us Global Navigation & Footer for AEM
 * by FleishmanHillard, c. 2018
 *
 * NOTE: ATTN DEVS!
 * Please use this stylesheet for importing SASS partials only. Do not author any SASS or CSS directly in this file.
 * Also, please first refer to the _abstracts partials to review the existing global style settings and utilities before creating new ones.
 *
 * For style guidelines and coding conventions for this project,
 * please consult the README.md in the root of the project.
 */

/*==========
  NAVIGATION
  ==========*/

  @charset 'utf-8';

/*=========
  Abstracts
  =========*/

  @import '_abstracts/variables';
  @import '_abstracts/mixins';

/*======
  Layout
  ======*/

  @import '_layout/header';
  @import '_layout/footer';

/*==========
  Components
  ==========*/

  @import '_components/nav';
